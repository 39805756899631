// Here you can add other styles
.link {
  color: #999;
  text-decoration: none;
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
}
.link:hover {
  color: #23263c;
}
.activeCategoryLink {
  color: #23263c;
  text-decoration: none;
  padding: 10px;
  font-weight: 600;
}
.sidebar-color{
  background-color: #17233e !important;
}
.sidebar-header{
  background-color: #17233e;
}
.nav-items{
  color: white;
  font-weight: bold;
}
button{
  background-color: #26a9e0;
}
.tableS {
  border-spacing: 0;
  border: 1px solid black;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
}
/* .btn{
  border-width: 0;
}
.btn-primary{
  background-color: #d91c5c;
}
.btn:hover{
  border-width: 0;
}
.btn-primary:hover{
  background-color: #d91c5c;
} */
/* .header{
  background-color: #ffdd15;
} */
.table-header{
 /*  border-radius: 15;
  background-color: #d91c5c;
  text-align: center;
  justify-content: center;
  align-items : center; */
}
.table-header-text{
 /*  color: white;
  text-align: center;
  justify-content: center;
  align-items : center; */
}

.bg-light {
  background-color: white !important;
}

.CellBackground {
background-color: "#69c4ea";
}

.glow {
  background-color: #FFFFC1;
  animation: glow-animation 1s infinite alternate;
}

@keyframes glow-animation {
  from {
    background-color: #FFFFC1;
    box-shadow: 0 0 5px #FFFFC1;
  }
  to {
    background-color: transparent;
    box-shadow: none;
  }
}

.posts {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid white;
}

.posts td, .posts th {
  border: 1px solid white;
  padding: 8px;
}

.posts tr:nth-child(even){background-color: #e9f5fc;}
.posts tr:nth-child(odd){background-color: #d2ebf8;}

// .posts tr:hover {background-color: #ddd;}

.posts th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}